import { api } from '../utils/api';
/** 获取账户信息  */
export async function getAccountInfo(data) {
    return api('/web/ec/account/info', data, 'POST', true, true, false);
}


/** 获取账户记录  */
export async function getAccountLogList(data) {
    return api('/web/ec/account/log/list', data, 'POST', true);
}